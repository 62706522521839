@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.common {
  --timing-function: cubic-bezier(0, 0.9, 0, 1);
  --animation-time: 0.3s;
  --common-transition: all var(--animation-time) var(--timing-function);
  --appear-animation: appear var(--animation-time) var(--timing-function) both;
}
.light {
  --theme: light;
  /* color */
  --white: white;
  --black: #212121;
  --gray: #fafafa;
  --primary: #2230e8;
  --second: rgba(34, 48, 232, 0.1);
  --hover-color: #f3f3f3;
  --bar-color: rgba(0, 0, 0, 0.1);
  --theme-color: var(--gray);
  /* shadow */
  --shadow: 50px 50px 100px 10px rgba(0, 0, 0, 0.1);
  --card-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  /* stroke */
  --border-in-light: 1px solid #dedede;
}
.dark {
  --theme: dark;
  /* color */
  --white: #212121;
  --black: #bbbbbb;
  --gray: #151515;
  --primary: #2230e8;
  --second: rgba(34, 48, 232, 0.1);
  --hover-color: #323232;
  --bar-color: rgba(255, 255, 255, 0.1);
  --border-in-light: 1px solid rgba(255, 255, 255, 0.192);
  --theme-color: var(--gray);
}
.dark div:not(.no-dark) > svg {
  filter: invert(0.5);
}
.pc {
  --sidebar-width: 350px;
  --window-content-width: calc(100% - var(--sidebar-width));
  --message-max-width: 80%;
  --message-input-width: calc(100% - 4ch);
}
.mobile {
  --sidebar-width: 100vw;
  --window-content-width: 100%;
  --message-max-width: 100%;
  --message-input-width: calc(100% - 4ch);
}
:root {
  --timing-function: cubic-bezier(0, 0.9, 0, 1);
  --animation-time: 0.3s;
  --common-transition: all var(--animation-time) var(--timing-function);
  --appear-animation: appear var(--animation-time) var(--timing-function) both;
  --theme: light;
  /* color */
  --white: white;
  --black: #212121;
  --gray: #fafafa;
  --primary: #2230e8;
  --second: rgba(34, 48, 232, 0.1);
  --hover-color: #f3f3f3;
  --bar-color: rgba(0, 0, 0, 0.1);
  --theme-color: var(--gray);
  /* shadow */
  --shadow: 50px 50px 100px 10px rgba(0, 0, 0, 0.1);
  --card-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  /* stroke */
  --border-in-light: 1px solid #dedede;
  --sidebar-width: 350px;
  --window-content-width: calc(100% - var(--sidebar-width));
  --message-max-width: 80%;
  --message-input-width: calc(100% - 4ch);
}
@media only screen and (max-width: 768px) {
  :root {
    --sidebar-width: 100vw;
    --window-content-width: 100%;
    --message-max-width: 100%;
    --message-input-width: calc(100% - 4ch);
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --theme: dark;
    /* color */
    --white: #212121;
    --black: #bbbbbb;
    --gray: #151515;
    --primary: #2230e8;
    --second: rgba(34, 48, 232, 0.1);
    --hover-color: #323232;
    --bar-color: rgba(255, 255, 255, 0.1);
    --border-in-light: 1px solid rgba(255, 255, 255, 0.192);
    --theme-color: var(--gray);
  }
  :root div:not(.no-dark) > svg {
    filter: invert(0.5);
  }
}
.light-mode {
  --theme: light;
  /* color */
  --white: white;
  --black: #212121;
  --gray: #fafafa;
  --primary: #2230e8;
  --second: rgba(34, 48, 232, 0.1);
  --hover-color: #f3f3f3;
  --bar-color: rgba(0, 0, 0, 0.1);
  --theme-color: var(--gray);
  /* shadow */
  --shadow: 50px 50px 100px 10px rgba(0, 0, 0, 0.1);
  --card-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  /* stroke */
  --border-in-light: 1px solid #dedede;
}
.dark-mode {
  --theme: dark;
  /* color */
  --white: #212121;
  --black: #bbbbbb;
  --gray: #151515;
  --primary: #2230e8;
  --second: rgba(34, 48, 232, 0.1);
  --hover-color: #323232;
  --bar-color: rgba(255, 255, 255, 0.1);
  --border-in-light: 1px solid rgba(255, 255, 255, 0.192);
  --theme-color: var(--gray);
}
.dark-mode div:not(.no-dark) > svg {
  filter: invert(0.5);
}
* {
  box-sizing: border-box;
  min-width: 0;
  padding: 0;
  margin: 0;
}
html,
body,
#entrypoint {
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans', 'SF Pro SC', 'SF Pro Text', 'SF Pro Icons', 'PingFang SC', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
body {
  background-color: var(--white);
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  touch-action: pan-x pan-y;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  border-style: dashed;
  background-color: var(--second);
  border-color: transparent;
  border-width: 4px;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}
select {
  border: var(--border-in-light);
  padding: 2ch;
  border-radius: 10px;
  appearance: none;
  cursor: pointer;
  background-color: var(--white);
  color: var(--black);
  text-align: center;
}
div.math {
  overflow-x: auto;
}
pre {
  position: relative;
}
pre:hover .copy-code-button {
  pointer-events: all;
  transform: translateX(0px);
  opacity: 0.5;
}
pre .copy-code-button {
  position: absolute;
  right: 10px;
  top: 1em;
  cursor: pointer;
  padding: 0px 5px;
  background-color: var(--black);
  color: var(--white);
  border: var(--border-in-light);
  border-radius: 10px;
  transform: translateX(10px);
  pointer-events: none;
  opacity: 0;
  transition: all ease 0.3s;
}
pre .copy-code-button:after {
  content: 'copy';
}
pre .copy-code-button:hover {
  opacity: 1;
}
input[type='checkbox'] {
  cursor: pointer;
  background-color: var(--white);
  color: var(--black);
  appearance: none;
  border: var(--border-in-light);
  border-radius: 5px;
  height: 16px;
  width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
input[type='checkbox']:checked::after {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  content: ' ';
  border-radius: 2px;
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link {
  font-size: 12px;
  color: var(--primary);
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  filter: brightness(0.9);
}
.clickable:focus {
  filter: brightness(0.95);
}
.error {
  width: 80%;
  border-radius: 20px;
  border: var(--border-in-light);
  box-shadow: var(--card-shadow);
  padding: 20px;
  overflow: auto;
  background-color: var(--white);
  color: var(--black);
}
.error pre {
  overflow: auto;
}
.selectable {
  border: 2px solid transparent;
  cursor: pointer;
}
.selectable.hover {
  background-color: var(--hover-color);
}
.selectable:hover {
  background-color: var(--hover-color);
}
.selectable.selected {
  border-color: var(--primary);
}
.disabled {
  pointer-events: none;
}
.hide {
  display: none !important;
}
.window-header {
  padding: 14px 20px;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.window-header-title {
  max-width: calc(100% - 100px);
  overflow: hidden;
}
.window-header-title .window-header-main-title {
  font-size: 20px;
  font-weight: bolder;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 50vw;
}
.window-header-title .window-header-sub-title {
  font-size: 14px;
}
.window-actions {
  display: inline-flex;
}
.window-action-button:not(:first-child) {
  margin-left: 10px;
}
.markdown-light {
  color-scheme: light;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #24292f;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: transparent;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsl(210, 18%, 87%);
  --color-neutral-muted: rgba(175, 184, 193, 0.2);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}
.markdown-dark {
  color-scheme: dark;
  --color-prettylights-syntax-comment: #8b949e;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-variable: #ffa657;
  --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-carriage-return-bg: #b62324;
  --color-prettylights-syntax-string-regexp: #7ee787;
  --color-prettylights-syntax-markup-list: #f2cc60;
  --color-prettylights-syntax-markup-heading: #1f6feb;
  --color-prettylights-syntax-markup-italic: #c9d1d9;
  --color-prettylights-syntax-markup-bold: #c9d1d9;
  --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
  --color-prettylights-syntax-markup-deleted-bg: #67060c;
  --color-prettylights-syntax-markup-inserted-text: #aff5b4;
  --color-prettylights-syntax-markup-inserted-bg: #033a16;
  --color-prettylights-syntax-markup-changed-text: #ffdfb6;
  --color-prettylights-syntax-markup-changed-bg: #5a1e02;
  --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
  --color-prettylights-syntax-markup-ignored-bg: #1158c7;
  --color-prettylights-syntax-meta-diff-range: #d2a8ff;
  --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
  --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
  --color-fg-default: #c9d1d9;
  --color-fg-muted: #8b949e;
  --color-fg-subtle: #6e7681;
  --color-canvas-default: transparent;
  --color-canvas-subtle: #161b22;
  --color-border-default: #30363d;
  --color-border-muted: #21262d;
  --color-neutral-muted: rgba(110, 118, 129, 0.4);
  --color-accent-fg: #58a6ff;
  --color-accent-emphasis: #1f6feb;
  --color-attention-subtle: rgba(187, 128, 9, 0.15);
  --color-danger-fg: #f85149;
}
.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  max-width: 100%;
  overflow-x: scroll;
}
.markdown-body td:first-child {
  min-width: 150px;
}
.markdown-body td:not(:first-child) {
  min-width: 300px;
  max-width: 80%;
}
:root {
  color-scheme: light;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #24292f;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: transparent;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsl(210, 18%, 87%);
  --color-neutral-muted: rgba(175, 184, 193, 0.2);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}
@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #c9d1d9;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-fg-default: #c9d1d9;
    --color-fg-muted: #8b949e;
    --color-fg-subtle: #6e7681;
    --color-canvas-default: transparent;
    --color-canvas-subtle: #161b22;
    --color-border-default: #30363d;
    --color-border-muted: #21262d;
    --color-neutral-muted: rgba(110, 118, 129, 0.4);
    --color-accent-fg: #58a6ff;
    --color-accent-emphasis: #1f6feb;
    --color-attention-subtle: rgba(187, 128, 9, 0.15);
    --color-danger-fg: #f85149;
  }
}
.markdown-body .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}
.markdown-body h1:hover .anchor .octicon-link:before,
.markdown-body h2:hover .anchor .octicon-link:before,
.markdown-body h3:hover .anchor .octicon-link:before,
.markdown-body h4:hover .anchor .octicon-link:before,
.markdown-body h5:hover .anchor .octicon-link:before,
.markdown-body h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-color: currentColor;
  -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
}
.markdown-body details,
.markdown-body figcaption,
.markdown-body figure {
  display: block;
}
.markdown-body summary {
  display: list-item;
}
.markdown-body [hidden] {
  display: none !important;
}
.markdown-body a {
  background-color: transparent;
  color: var(--color-accent-fg);
  text-decoration: none;
}
.markdown-body abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}
.markdown-body b,
.markdown-body strong {
  font-weight: var(--base-text-weight-semibold, 600);
}
.markdown-body dfn {
  font-style: italic;
}
.markdown-body h1 {
  margin: 0.67em 0;
  font-weight: var(--base-text-weight-semibold, 600);
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid var(--color-border-muted);
}
.markdown-body mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-fg-default);
}
.markdown-body small {
  font-size: 90%;
}
.markdown-body sub,
.markdown-body sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.markdown-body sub {
  bottom: -0.25em;
}
.markdown-body sup {
  top: -0.5em;
}
.markdown-body img {
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);
}
.markdown-body code,
.markdown-body kbd,
.markdown-body pre,
.markdown-body samp {
  font-family: monospace;
  font-size: 1em;
}
.markdown-body figure {
  margin: 1em 40px;
}
.markdown-body hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid var(--color-border-muted);
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: var(--color-border-default);
  border: 0;
}
.markdown-body input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.markdown-body [type='button'],
.markdown-body [type='reset'],
.markdown-body [type='submit'] {
  appearance: button;
  -webkit-appearance: button;
}
.markdown-body [type='checkbox'],
.markdown-body [type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
.markdown-body [type='number']::-webkit-inner-spin-button,
.markdown-body [type='number']::-webkit-outer-spin-button {
  height: auto;
}
.markdown-body [type='search']::-webkit-search-cancel-button,
.markdown-body [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
.markdown-body ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
.markdown-body ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.markdown-body a:hover {
  text-decoration: underline;
}
.markdown-body ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1;
}
.markdown-body hr::before {
  display: table;
  content: '';
}
.markdown-body hr::after {
  display: table;
  clear: both;
  content: '';
}
.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
  display: inline;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}
.markdown-body td,
.markdown-body th {
  padding: 0;
}
.markdown-body details summary {
  cursor: pointer;
}
.markdown-body details:not([open]) > *:not(summary) {
  display: none !important;
}
.markdown-body a:focus,
.markdown-body [role='button']:focus,
.markdown-body input[type='radio']:focus,
.markdown-body input[type='checkbox']:focus {
  outline: 2px solid var(--color-accent-fg);
  outline-offset: -2px;
  box-shadow: none;
}
.markdown-body a:focus:not(:focus-visible),
.markdown-body [role='button']:focus:not(:focus-visible),
.markdown-body input[type='radio']:focus:not(:focus-visible),
.markdown-body input[type='checkbox']:focus:not(:focus-visible) {
  outline: solid 1px transparent;
}
.markdown-body a:focus-visible,
.markdown-body [role='button']:focus-visible,
.markdown-body input[type='radio']:focus-visible,
.markdown-body input[type='checkbox']:focus-visible {
  outline: 2px solid var(--color-accent-fg);
  outline-offset: -2px;
  box-shadow: none;
}
.markdown-body a:not([class]):focus,
.markdown-body a:not([class]):focus-visible,
.markdown-body input[type='radio']:focus,
.markdown-body input[type='radio']:focus-visible,
.markdown-body input[type='checkbox']:focus,
.markdown-body input[type='checkbox']:focus-visible {
  outline-offset: 0;
}
.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: var(--base-text-weight-semibold, 600);
  line-height: 1.25;
}
.markdown-body h2 {
  font-weight: var(--base-text-weight-semibold, 600);
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid var(--color-border-muted);
}
.markdown-body h3 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 1.25em;
}
.markdown-body h4 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 1em;
}
.markdown-body h5 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 0.875em;
}
.markdown-body h6 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 0.85em;
  color: var(--color-fg-muted);
}
.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}
.markdown-body blockquote {
  margin: 0;
  padding: 0 1em;
  color: var(--color-fg-muted);
  border-left: 0.25em solid var(--color-border-default);
}
.markdown-body ul,
.markdown-body ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}
.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}
.markdown-body ul ul ol,
.markdown-body ul ol ol,
.markdown-body ol ul ol,
.markdown-body ol ol ol {
  list-style-type: lower-alpha;
}
.markdown-body dd {
  margin-left: 0;
}
.markdown-body tt,
.markdown-body code,
.markdown-body samp {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}
.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
  word-wrap: normal;
}
.markdown-body .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}
.markdown-body input::-webkit-outer-spin-button,
.markdown-body input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}
.markdown-body > *:first-child {
  margin-top: 0 !important;
}
.markdown-body > *:last-child {
  margin-bottom: 0 !important;
}
.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.markdown-body .absent {
  color: var(--color-danger-fg);
}
.markdown-body .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.markdown-body .anchor:focus {
  outline: none;
}
.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre,
.markdown-body details {
  margin-top: 0;
  margin-bottom: 16px;
}
.markdown-body blockquote > :first-child {
  margin-top: 0;
}
.markdown-body blockquote > :last-child {
  margin-bottom: 0;
}
.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden;
}
.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none;
}
.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}
.markdown-body h1 tt,
.markdown-body h1 code,
.markdown-body h2 tt,
.markdown-body h2 code,
.markdown-body h3 tt,
.markdown-body h3 code,
.markdown-body h4 tt,
.markdown-body h4 code,
.markdown-body h5 tt,
.markdown-body h5 code,
.markdown-body h6 tt,
.markdown-body h6 code {
  padding: 0 0.2em;
  font-size: inherit;
}
.markdown-body summary h1,
.markdown-body summary h2,
.markdown-body summary h3,
.markdown-body summary h4,
.markdown-body summary h5,
.markdown-body summary h6 {
  display: inline-block;
}
.markdown-body summary h1 .anchor,
.markdown-body summary h2 .anchor,
.markdown-body summary h3 .anchor,
.markdown-body summary h4 .anchor,
.markdown-body summary h5 .anchor,
.markdown-body summary h6 .anchor {
  margin-left: -40px;
}
.markdown-body summary h1,
.markdown-body summary h2 {
  padding-bottom: 0;
  border-bottom: 0;
}
.markdown-body ul.no-list,
.markdown-body ol.no-list {
  padding: 0;
  list-style-type: none;
}
.markdown-body ol[type='a'] {
  list-style-type: lower-alpha;
}
.markdown-body ol[type='A'] {
  list-style-type: upper-alpha;
}
.markdown-body ol[type='i'] {
  list-style-type: lower-roman;
}
.markdown-body ol[type='I'] {
  list-style-type: upper-roman;
}
.markdown-body ol[type='1'] {
  list-style-type: decimal;
}
.markdown-body div > ol:not([type]) {
  list-style-type: decimal;
}
.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body li > p {
  margin-top: 16px;
}
.markdown-body li + li {
  margin-top: 0.25em;
}
.markdown-body dl {
  padding: 0;
}
.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: var(--base-text-weight-semibold, 600);
}
.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.markdown-body table th {
  font-weight: var(--base-text-weight-semibold, 600);
}
.markdown-body table th,
.markdown-body table td {
  padding: 6px 13px;
  border: 1px solid var(--color-border-default);
}
.markdown-body table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}
.markdown-body table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}
.markdown-body table img {
  background-color: transparent;
}
.markdown-body img[align='right'] {
  padding-left: 20px;
}
.markdown-body img[align='left'] {
  padding-right: 20px;
}
.markdown-body .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent;
}
.markdown-body span.frame {
  display: block;
  overflow: hidden;
}
.markdown-body span.frame > span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid var(--color-border-default);
}
.markdown-body span.frame span img {
  display: block;
  float: left;
}
.markdown-body span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: var(--color-fg-default);
}
.markdown-body span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}
.markdown-body span.align-center > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}
.markdown-body span.align-center span img {
  margin: 0 auto;
  text-align: center;
}
.markdown-body span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}
.markdown-body span.align-right > span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}
.markdown-body span.align-right span img {
  margin: 0;
  text-align: right;
}
.markdown-body span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}
.markdown-body span.float-left span {
  margin: 13px 0 0;
}
.markdown-body span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}
.markdown-body span.float-right > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}
.markdown-body code,
.markdown-body tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: var(--color-neutral-muted);
  border-radius: 6px;
}
.markdown-body code br,
.markdown-body tt br {
  display: none;
}
.markdown-body del code {
  text-decoration: inherit;
}
.markdown-body samp {
  font-size: 85%;
}
.markdown-body pre code {
  font-size: 100%;
}
.markdown-body pre > code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.markdown-body .highlight {
  margin-bottom: 16px;
}
.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}
.markdown-body .highlight pre,
.markdown-body pre {
  padding: 16px 16px 8px 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  border-radius: 6px;
  direction: ltr;
}
.markdown-body pre code,
.markdown-body pre tt {
  display: inline-block;
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: scroll;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
.markdown-body .csv-data td,
.markdown-body .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}
.markdown-body .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: var(--color-canvas-default);
  border: 0;
}
.markdown-body .csv-data tr {
  border-top: 0;
}
.markdown-body .csv-data th {
  font-weight: var(--base-text-weight-semibold, 600);
  background: var(--color-canvas-subtle);
  border-top: 0;
}
.markdown-body [data-footnote-ref]::before {
  content: '[';
}
.markdown-body [data-footnote-ref]::after {
  content: ']';
}
.markdown-body .footnotes {
  font-size: 12px;
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default);
}
.markdown-body .footnotes ol {
  padding-left: 16px;
}
.markdown-body .footnotes ol ul {
  display: inline-block;
  padding-left: 16px;
  margin-top: 16px;
}
.markdown-body .footnotes li {
  position: relative;
}
.markdown-body .footnotes li:target::before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: '';
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px;
}
.markdown-body .footnotes li:target {
  color: var(--color-fg-default);
}
.markdown-body .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}
.markdown-body .pl-c {
  color: var(--color-prettylights-syntax-comment);
}
.markdown-body .pl-c1,
.markdown-body .pl-s .pl-v {
  color: var(--color-prettylights-syntax-constant);
}
.markdown-body .pl-e,
.markdown-body .pl-en {
  color: var(--color-prettylights-syntax-entity);
}
.markdown-body .pl-smi,
.markdown-body .pl-s .pl-s1 {
  color: var(--color-prettylights-syntax-storage-modifier-import);
}
.markdown-body .pl-ent {
  color: var(--color-prettylights-syntax-entity-tag);
}
.markdown-body .pl-k {
  color: var(--color-prettylights-syntax-keyword);
}
.markdown-body .pl-s,
.markdown-body .pl-pds,
.markdown-body .pl-s .pl-pse .pl-s1,
.markdown-body .pl-sr,
.markdown-body .pl-sr .pl-cce,
.markdown-body .pl-sr .pl-sre,
.markdown-body .pl-sr .pl-sra {
  color: var(--color-prettylights-syntax-string);
}
.markdown-body .pl-v,
.markdown-body .pl-smw {
  color: var(--color-prettylights-syntax-variable);
}
.markdown-body .pl-bu {
  color: var(--color-prettylights-syntax-brackethighlighter-unmatched);
}
.markdown-body .pl-ii {
  color: var(--color-prettylights-syntax-invalid-illegal-text);
  background-color: var(--color-prettylights-syntax-invalid-illegal-bg);
}
.markdown-body .pl-c2 {
  color: var(--color-prettylights-syntax-carriage-return-text);
  background-color: var(--color-prettylights-syntax-carriage-return-bg);
}
.markdown-body .pl-sr .pl-cce {
  font-weight: bold;
  color: var(--color-prettylights-syntax-string-regexp);
}
.markdown-body .pl-ml {
  color: var(--color-prettylights-syntax-markup-list);
}
.markdown-body .pl-mh,
.markdown-body .pl-mh .pl-en,
.markdown-body .pl-ms {
  font-weight: bold;
  color: var(--color-prettylights-syntax-markup-heading);
}
.markdown-body .pl-mi {
  font-style: italic;
  color: var(--color-prettylights-syntax-markup-italic);
}
.markdown-body .pl-mb {
  font-weight: bold;
  color: var(--color-prettylights-syntax-markup-bold);
}
.markdown-body .pl-md {
  color: var(--color-prettylights-syntax-markup-deleted-text);
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}
.markdown-body .pl-mi1 {
  color: var(--color-prettylights-syntax-markup-inserted-text);
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}
.markdown-body .pl-mc {
  color: var(--color-prettylights-syntax-markup-changed-text);
  background-color: var(--color-prettylights-syntax-markup-changed-bg);
}
.markdown-body .pl-mi2 {
  color: var(--color-prettylights-syntax-markup-ignored-text);
  background-color: var(--color-prettylights-syntax-markup-ignored-bg);
}
.markdown-body .pl-mdr {
  font-weight: bold;
  color: var(--color-prettylights-syntax-meta-diff-range);
}
.markdown-body .pl-ba {
  color: var(--color-prettylights-syntax-brackethighlighter-angle);
}
.markdown-body .pl-sg {
  color: var(--color-prettylights-syntax-sublimelinter-gutter-mark);
}
.markdown-body .pl-corl {
  text-decoration: underline;
  color: var(--color-prettylights-syntax-constant-other-reference-link);
}
.markdown-body g-emoji {
  display: inline-block;
  min-width: 1ch;
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1em;
  font-style: normal !important;
  font-weight: var(--base-text-weight-normal, 400);
  line-height: 1;
  vertical-align: -0.075em;
}
.markdown-body g-emoji img {
  width: 1em;
  height: 1em;
}
.markdown-body .task-list-item {
  list-style-type: none;
}
.markdown-body .task-list-item label {
  font-weight: var(--base-text-weight-normal, 400);
}
.markdown-body .task-list-item.enabled label {
  cursor: pointer;
}
.markdown-body .task-list-item + .task-list-item {
  margin-top: 4px;
}
.markdown-body .task-list-item .handle {
  display: none;
}
.markdown-body .task-list-item-checkbox {
  margin: 0 0.2em 0.25em -1.4em;
  vertical-align: middle;
}
.markdown-body .contains-task-list:dir(rtl) .task-list-item-checkbox {
  margin: 0 -1.6em 0.25em 0.2em;
}
.markdown-body .contains-task-list {
  position: relative;
}
.markdown-body .contains-task-list:hover .task-list-item-convert-container,
.markdown-body .contains-task-list:focus-within .task-list-item-convert-container {
  display: block;
  width: auto;
  height: 24px;
  overflow: visible;
  clip: auto;
}
.markdown-body ::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
.markdown-body .mermaid {
  border: var(--border-in-light);
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  background-color: var(--white);
}
#dmermaid {
  display: none;
}
.markdown-body {
  /*!
  Theme: Tokyo-night-Dark
  origin: https://github.com/enkia/tokyo-night-vscode-theme
  Description: Original highlight.js style
  Author: (c) Henri Vandersleyen <hvandersleyen@gmail.com>
  License: see project LICENSE
  Touched: 2022
*/
}
.markdown-body pre {
  padding: 0;
}
.markdown-body pre,
.markdown-body code {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}
.markdown-body pre code {
  display: block;
  overflow-x: auto;
  padding: 1em;
}
.markdown-body code {
  padding: 3px 5px;
}
.markdown-body .hljs,
.markdown-body pre {
  background: #1a1b26;
  color: #cbd2ea;
}
.markdown-body .hljs-comment,
.markdown-body .hljs-meta {
  color: #565f89;
}
.markdown-body .hljs-deletion,
.markdown-body .hljs-doctag,
.markdown-body .hljs-regexp,
.markdown-body .hljs-selector-attr,
.markdown-body .hljs-selector-class,
.markdown-body .hljs-selector-id,
.markdown-body .hljs-selector-pseudo,
.markdown-body .hljs-tag,
.markdown-body .hljs-template-tag,
.markdown-body .hljs-variable.language_ {
  color: #f7768e;
}
.markdown-body .hljs-link,
.markdown-body .hljs-literal,
.markdown-body .hljs-number,
.markdown-body .hljs-params,
.markdown-body .hljs-template-variable,
.markdown-body .hljs-type,
.markdown-body .hljs-variable {
  color: #ff9e64;
}
.markdown-body .hljs-attribute,
.markdown-body .hljs-built_in {
  color: #e0af68;
}
.markdown-body .hljs-keyword,
.markdown-body .hljs-property,
.markdown-body .hljs-subst,
.markdown-body .hljs-title,
.markdown-body .hljs-title.class_,
.markdown-body .hljs-title.class_.inherited__,
.markdown-body .hljs-title.function_ {
  color: #7dcfff;
}
.markdown-body .hljs-selector-tag {
  color: #73daca;
}
.markdown-body .hljs-addition,
.markdown-body .hljs-bullet,
.markdown-body .hljs-quote,
.markdown-body .hljs-string,
.markdown-body .hljs-symbol {
  color: #9ece6a;
}
.markdown-body .hljs-code,
.markdown-body .hljs-formula,
.markdown-body .hljs-section {
  color: #7aa2f7;
}
.markdown-body .hljs-attr,
.markdown-body .hljs-char.escape_,
.markdown-body .hljs-keyword,
.markdown-body .hljs-name,
.markdown-body .hljs-operator {
  color: #bb9af7;
}
.markdown-body .hljs-punctuation {
  color: #c0caf5;
}
.markdown-body .hljs-emphasis {
  font-style: italic;
}
.markdown-body .hljs-strong {
  font-weight: 700;
}
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-in-from-top {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-in-from-right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
