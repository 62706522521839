* {
  box-sizing: border-box;
  min-width: 0;
  padding: 0;
  margin: 0;
}

html,
body,
#entrypoint {
  width: 100%;
  height: 100%;

  font-family: 'Noto Sans', 'SF Pro SC', 'SF Pro Text', 'SF Pro Icons', 'PingFang SC', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}

body {
  background-color: var(--white);
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  touch-action: pan-x pan-y;
  overflow: hidden;

  // @media only screen and (max-width: 768px) {
  //   background-color: var(--second);
  // }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;

  &-track {
    background-color: transparent;
  }

  &-thumb {
    border-radius: 0;
    border-style: dashed;
    background-color: var(--second);
    border-color: transparent;
    border-width: 4px;
    background-clip: padding-box;

    &:hover {
      background: var(--primary);
    }
  }
}

select {
  border: var(--border-in-light);
  padding: 2ch;
  border-radius: 10px;
  appearance: none;
  cursor: pointer;
  background-color: var(--white);
  color: var(--black);
  text-align: center;
}

div.math {
  overflow-x: auto;
}

pre {
  position: relative;

  &:hover .copy-code-button {
    pointer-events: all;
    transform: translateX(0px);
    opacity: 0.5;
  }

  .copy-code-button {
    position: absolute;
    right: 10px;
    top: 1em;
    cursor: pointer;
    padding: 0px 5px;
    background-color: var(--black);
    color: var(--white);
    border: var(--border-in-light);
    border-radius: 10px;
    transform: translateX(10px);
    pointer-events: none;
    opacity: 0;
    transition: all ease 0.3s;

    &:after {
      content: 'copy';
    }

    &:hover {
      opacity: 1;
    }
  }
}

input[type='checkbox'] {
  cursor: pointer;
  background-color: var(--white);
  color: var(--black);
  appearance: none;
  border: var(--border-in-light);
  border-radius: 5px;
  height: 16px;
  width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type='checkbox']:checked::after {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  content: ' ';
  border-radius: 2px;
}
